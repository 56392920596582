export const routeConstants = {
    SUPERADMIN_HOME: '/superadmin',
    CC_HOME: '/carecoordinator',
    HR_HOME: '/hr',
    DOCTOR_HOME: '/doctor',
    PATIENT_HOME: '/patient',
    INDEX: '/login',
    DOCTOR_LOGIN: '/doctor/login',
    CC_LOGIN: '/carecoordinator/login',
    SA_LOGIN: '/superadmin/login',
    HR_LOGIN: '/hr/login'
}