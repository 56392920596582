import { LOGIN_USER, LOGOUT_USER, UserActionTypes } from "../../types/userActions"

const defaultState: { user: any } = { user: {} };

export default function (state = defaultState, action: UserActionTypes): { user: any } {
    // console.log('BUKHAR', action, state)
    switch (action.type) {
        case LOGIN_USER: return {
            ...state,
            user: action.payload,
        }
        case LOGOUT_USER: return {
            ...state,
            user: {},
        }
        default:
            return state
    }

}