import { ADD_FILTER, CaseActionTypes } from "../../types/caseActions"

const defaultState: { filters: any } = { filters: {} };
export default function (state = defaultState, action: CaseActionTypes): { filters: any } {
    switch (action.type) {
        case ADD_FILTER:
            const { key, value } = action.payload;
            return {
                ...state,
                filters: { ...state.filters, [key]: value }
            }
        default:
            return state
    }
}