import { combineReducers } from 'redux';
import authReducer from './authReducer';
import commonReducer from './commonReducer';
import caseReducer from './caseReducer';
import appointmentReducer from './appointmentReducer';

const rootReducer = combineReducers({
  auth: authReducer,
  common: commonReducer,
  case: caseReducer,
  appointment: appointmentReducer,
});

export type AppState = ReturnType<typeof rootReducer>;
export default rootReducer;
