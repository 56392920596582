import thunk, { ThunkMiddleware } from 'redux-thunk';
import thunkMiddleware from 'redux-thunk'
import { createLogger } from 'redux-logger';
import { AppState } from '../store/reducers';
import { AppActions } from '../types/actions';
import { axiosInstance } from "./../utilities/configureAxios";

/**
 * logs store update when dispatching
 *
 * @param  {[type]} store 
 * @return {[type]}
 */

const logger = createLogger();

/**
 * returns list of middlewares
 *
 * @return Array[]
 */
const configureMiddlewares = () => {

    const middlewares = [
        (thunk as ThunkMiddleware<AppState, AppActions> & {
            withExtraArgument<E>(extraArgument: E): ThunkMiddleware<AppState, AppActions, E>
        }).withExtraArgument({
            api: axiosInstance
        })];

    if (process.env.NODE_ENV === "development") {
        middlewares.push(logger);
    }

    return middlewares;
};

export default configureMiddlewares();