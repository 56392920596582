export const LOGIN_USER = 'LOGIN_USER';
export const LOGOUT_USER = 'LOGOUT_USER';

export interface LoginUserAction {
    type: typeof LOGIN_USER;
    payload: any;
}

export interface LogoutUserAction {
    type: typeof LOGOUT_USER;
    payload: any;
}

export type UserActionTypes = LoginUserAction | LogoutUserAction;