import middlewares from '../../middlewares/middleware';
import reducers from '../reducers/persistReducer';
import { createStore, applyMiddleware, compose } from 'redux';
import { persistStore } from 'redux-persist'

const configureStore = () => {
    let reduxEnhancers;
    reduxEnhancers = compose(applyMiddleware(...middlewares));
    const store: any = createStore(
        reducers,
        reduxEnhancers
    )
    const persistor = persistStore(store)
    return { store, persistor };
}
export default configureStore();