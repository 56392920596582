import {
  SetAppointmentAction,
  SET_APPOINTMENT_METADATA,
} from '../../types/appointmentActions';

const defaultState: { appointments: any } = { appointments: {} };

export default function (
  state = defaultState,
  action: SetAppointmentAction,
): { appointments: {} } {
  // console.log('PRACHI DOLO',action)
  switch (action.type) {
    case SET_APPOINTMENT_METADATA:
      // console.log('paylaodss', action.payload);

      var keys: any;
      var value: any;

      Object.keys(action.payload).forEach(function (key) {
        value = action.payload[key];
        keys = key;
      });
      // console.log('CHECKLIST',keys, value)
      return {
        ...state,
        appointments: {
          ...state.appointments,
          [keys]: value,
        },
      };
    default:
      return state;
  }
}
