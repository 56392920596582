import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import rootReducer from './index';

const persistConfig = {
    key: 'tconsulta',
    storage
}
const configPersistreducer = () => {
    const persistedReducer = persistReducer(persistConfig, rootReducer);
    return persistedReducer;
}

export default configPersistreducer();