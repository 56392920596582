import React, { Component } from 'react'
// import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Redirect, Route, RouteComponentProps } from 'react-router-dom'
import { routeConstants } from '../constants/routes';
const { DOCTOR_HOME, PATIENT_HOME, CC_HOME, SUPERADMIN_HOME, HR_HOME, INDEX, HR_LOGIN, DOCTOR_LOGIN, CC_LOGIN, SA_LOGIN } = routeConstants
interface PropsType extends RouteComponentProps {
    match: any,
    auth: any

}
export function requireAuth(component: any) {
    class PrivateRoute extends Component<PropsType, {}> {

        render() {
            // console.log(this.props.match.path)
            let path = this.props.match.path;
            if (path.startsWith('/candidate')) {
                // console.log('okokoko');
            }


            let userDetails = this.props.auth && this.props.auth.user;
            if ((Object.keys(userDetails).length) !== 0) {
                //find the user role
                const userRole = userDetails.data && userDetails.data.role && userDetails.data.role.name;

                //check the path and userdetails
                if (userDetails.data && userDetails.data.isHr && path.startsWith(HR_HOME)) {
                    return <Route to={path} component={component} />
                }
                else if (userRole == 'Superadmin' && path.startsWith(SUPERADMIN_HOME)) {
                    return <Route to={path} component={component} />
                }
                else if (userRole == 'Patient' && path.startsWith(PATIENT_HOME)) {
                    return <Route to={path} component={component} />
                }

                else if (userRole == 'Doctor' && path.startsWith(DOCTOR_HOME)) {
                    return <Route to={path} component={component} />
                }
                else if (userRole == 'Care Coordinator' && path.startsWith(CC_HOME)) {
                    return <Route to={path} component={component} />
                }
                else {
                    const route: string = this.props.location.pathname;
                    if (route !== HR_LOGIN && route !== DOCTOR_LOGIN && route !== CC_LOGIN && route !== SA_LOGIN) {
                        return <Redirect to={INDEX} />
                    }
                }

            }
            else {
                const route: string = this.props.location.pathname;
                if (route == HR_HOME) {
                    return <Redirect to={HR_LOGIN} />
                }
                else if (route == DOCTOR_HOME) {
                    return <Redirect to={DOCTOR_LOGIN} />
                } else if (route == CC_HOME) {
                    // console.log("uder this")
                    return <Redirect to={CC_LOGIN} />
                }
                else if (route == SUPERADMIN_HOME) {
                    return <Redirect to={SA_LOGIN} />
                }
                else {
                    return <Redirect to={INDEX} />
                }

            }
        }



    }
    const mapStateToProps = ({ auth }: { auth: any }) => {
        return {
            auth
        }
    }

    return connect(mapStateToProps)(PrivateRoute)
}

